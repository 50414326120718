import { Menu } from "@/types";

export const accountMenu: Menu = {
  id: 1,
  title: "アカウント情報",
  url: "",
  icon: "user-1",
  items: [
    {
      id: 1,
      title: "アカウント情報の確認",
      url: "/userinfo"
    },
    {
      id: 2,
      title: "アカウント情報の公開設定",
      url: "/user-info-publish-setting"
    }
  ]
};

export const ibowMenu: Menu = {
  id: 2,
  title: "iBow",
  url: "",
  icon: "receipt",
  items: [
    // {
    //   id: 1,
    //   title: "新規契約のお申し込み",
    //   url: "/appform",
    //   is_outer: true,
    // },
    {
      id: 2,
      title: "契約情報",
      url: "/ibow2/contract/info"
    },
    {
      id: 3,
      title: "所属一覧",
      url: "/ibow2/office/list"
    }
  ]
};

export const ibowMenuNotAgree: Menu = {
  id: 2,
  title: "iBow",
  url: "",
  icon: "receipt",
  items: [
    {
      id: 3,
      title: "所属一覧",
      url: "/ibow2/office/list"
    }
  ]
};

export const calendarMenu: Menu = {
  id: 3,
  title: "カレンダー",
  url: "",
  icon: "calendar-1",
  items: [
    {
      id: 1,
      title: "マイカレンダー",
      url: "/calendar/mycalendar"
    }
  ]
};

export const portalMenu: Menu = {
  id: 4,
  title: "けあログっと",
  url: "",
  icon: "form",
  items: [
    {
      id: 1,
      title: "所属一覧",
      url: "/carelogood/account/list"
    }
  ]
};

// const accountHistoryMenu: Menu = {
//   id: 4,
//   title: "職員紐付け履歴",
//   url: "",
//   icon: "form",
//   items: [
//     {
//       id: 1,
//       title: "紐付け履歴",
//       url: "/office-staff/link-histories"
//     }
//   ]
// };

export const linkMenu: Menu = {
  id: 5,
  title: "iBow e-ストア",
  url: "/service-link",
  icon: "form",
  items: []
};

/** 請求ダッシュボード */
export const invoiceDashboardMenu: Menu = {
  id: 6,
  title: "請求ダッシュボード",
  url: "/invoicedashboard",
  icon: "receipt",
  items: []
};
