























































import { Component, Vue, Prop, Emit } from "vue-property-decorator";
import { RepeatPeriodDiv, RepeatDiv } from "./common";
import NumberInput from "../NumberInput.vue";
import AppDayPicker from "../TextDayChangePicker.vue";

@Component({
  components: {
    NumberInput,
    AppDayPicker
  }
})
export default class RepeateLimitArea extends Vue {
  /*
   * prop
   */

  @Prop({ default: "prefix" }) private prefix_id!: string;
  // 期限終了日必須フラグ
  @Prop({ default: false }) requiredLimitEndDate!: boolean;
  // 期限開始日非活性フラグ
  @Prop({ default: false }) disabledLimitStart!: boolean;
  // 繰り返し：これ以降の予定 選択時の期限開始日
  @Prop({ default: "" }) afterLimitStartDate!: string;
  // 繰り返し区分
  @Prop() repeat_div!: number;

  // 期限区分
  @Prop() limit_div!: number;
  // 期限開始日
  @Prop() limit_start_date!: string;
  // 期限終了日
  @Prop() limit_end_date!: string;
  // 期限回数
  @Prop() limit_count!: number;

  /*
   * emit
   */

  @Emit("update:limit_div")
  private updateLimitDiv(val: number): number {
    return val;
  }
  @Emit("update:limit_start_date")
  private updateLimitStartDate(val: string): string {
    return val;
  }
  @Emit("update:limit_end_date")
  private updateLimitEndDate(val: string): string {
    return val;
  }
  @Emit("update:limit_count")
  private updateLimitCount(val: number): number {
    return val;
  }

  /*
   * data
   */
  private RepeatPeriodDiv = RepeatPeriodDiv;

  /*
   * computed
   */

  // 期限区分
  private get LimitDiv(): number {
    return this.limit_div;
  }
  private set LimitDiv(val: number) {
    this.updateLimitDiv(val);
  }

  // 期限開始日
  private get LimitStartDate(): string {
    if (this.disabledLimitStart) {
      return this.afterLimitStartDate;
    }
    return this.limit_start_date;
  }
  private set LimitStartDate(val: string) {
    this.updateLimitStartDate(val);
  }

  // 期限終了日
  private get LimitEndDate(): string {
    return this.limit_end_date;
  }
  private set LimitEndDate(val: string) {
    this.updateLimitEndDate(val);
  }

  // 期限回数
  private get LimitCount(): number {
    return this.limit_count;
  }
  private set LimitCount(val: number) {
    this.updateLimitCount(val);
  }

  // 期限：カウント 必須フラグ
  private get requierdLimitCount(): boolean {
    if (
      this.repeat_div != RepeatDiv.None &&
      this.LimitDiv == RepeatPeriodDiv.Count
    ) {
      return true;
    }
    return false;
  }

  /*
   * method
   */

  // イベント 期限開始日変更
  private changeLimitStartDate() {
    // 期限区分が日付でない場合は何もしない
    if (this.limit_div !== RepeatPeriodDiv.Date) {
      return;
    }
    this.$nextTick(() => {
      // 期限終了日 < 期限開始日の場合
      if (this.LimitEndDate && this.LimitEndDate < this.LimitStartDate) {
        // 訪問終了日に訪問開始日をセット
        this.LimitEndDate = this.LimitStartDate;
      }
    });
  }

  // バリデーション 期限：カウント
  private validateLimitCount(): boolean | string {
    if (!this.requierdLimitCount) {
      return true;
    }
    if (this.LimitCount <= 0) {
      return "回数は1以上を指定してください";
    }
    if (this.LimitCount > 2000) {
      return "回数は2000以下を指定してください";
    }
    return true;
  }

  // バリデーション 期限：日付
  private validateLimitDate(): boolean | string {
    if (this.repeat_div === RepeatDiv.None) {
      return true;
    }
    if (this.LimitDiv !== RepeatPeriodDiv.Date) {
      return true;
    }
    if (this.LimitEndDate === "") {
      return true;
    }
    if (this.LimitEndDate < this.LimitStartDate) {
      return "期限日が不正です";
    }
    return true;
  }
}
