import { MasterState } from "./types";
import { Module } from "vuex";
import { RootState } from "@/store/types";
import { actions } from "./actions";
import { mutations } from "./mutations";
import { getters } from "./getters";

export const state: MasterState = {
  loginUser: {
    id: 0,
    ibow_id: "",
    family_name: "",
    first_name: "",
    birthday: ""
  },
  menuInfo: {
    is_menu_agree_info: 0,
    is_use_ibow: 0,
    is_use_portal: 0,
    is_menu_invoice_dashboard: 0
  },
  error: false
};

const namespaced = true;

export const master: Module<MasterState, RootState> = {
  namespaced,
  state,
  actions,
  mutations,
  getters
};
