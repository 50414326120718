





































import { Component, Ref, Prop, Vue, Emit } from "vue-property-decorator";
import SelectFilter from "#/components/selectFilter/SelectFilter.vue";
import { SELECT_FILTER } from "#/const";
import {
  ColumnKey,
  HeaderColumn,
  IsFilterMatch,
} from "#/components/selectFilter/filter";
import { InvoiceItem } from "@/views/invoicedashboard/types";
import * as appDate from "#/utility/appDate";

@Component({ components: { SelectFilter } })
export default class InvoiceList extends Vue {

  /** Ref */

  /** フィルター制御 */
  @Ref("selectFilter") private readonly selectFilter!: SelectFilter;

  /** Prop */

  /** 請求履歴 */
  @Prop({ default: () => [] }) invoiceItems!: InvoiceItem[];

  /** ローディング中フラグ */
  @Prop({ default: () => true }) isLoading!: boolean;

  /** フィルター用法人名リスト */
  @Prop({ default: () => [] }) coNames!: string[];

  /** フィルター用事業所名リスト */
  @Prop({ default: () => [] }) officeNames!: string[];

  /** 変数 */

  /** 1ページあたりの表示件数 */
  private itemPerPage = 25;

  /** 表の現在のページ数 */
  private pageNo = 1;

  /** getter */

  /** データテーブルヘッダー */
  private get Headers(): HeaderColumn[] {
    return [
      {
        text: "",
        align: "start",
        value: "actions",
        width: "30px",
        sortable: false,
      },
      {
        text: "請求年月",
        align: "start",
        value: "invoice_yearmonth",
        width: "120px",
        sortable: true,
        filter: (val: object) => this.columnFilter("invoice_yearmonth", val),
          columnKey: {
            type: SELECT_FILTER.FILTER_TYPE.MONTH,
            key: "invoice_yearmonth",
            text: "請求年月",
            selectable: [],
          },
      },
      {
        text: "企業コード",
        align: "start",
        value: "co_code",
        width: "80px",
        sortable: true,
      },
      {
        text: "法人名",
        align: "start",
        value: "co_name",
        width: "",
        sortable: true,
        filter: (val: object) => this.columnFilter("co_name", val),
          columnKey: {
            type: SELECT_FILTER.FILTER_TYPE.LIST,
            key: "co_name",
            text: "法人名",
            selectable: this.coNames,
          },
      },
      {
        text: "事業所コード",
        align: "start",
        value: "kst_no",
        width: "100px",
        sortable: true,
      },
      {
        text: "事業所名",
        align: "start",
        value: "office_name",
        width: "",
        sortable: true,
        filter: (val: object) => this.columnFilter("office_name", val),
          columnKey: {
            type: SELECT_FILTER.FILTER_TYPE.LIST,
            key: "office_name",
            text: "事業所名",
            selectable: this.officeNames,
          },
      }
    ];
  }

  /** 関数 */

  /** データテーブルのページ変更 */
  private changePage(pageNo: number) {
    this.pageNo = pageNo;
  }

  /** SelectFilterの選択肢 */
  private get columnKeys(): ColumnKey[] {
    const keys = this.Headers
      .filter((headerColumn: HeaderColumn) => headerColumn.filter != null)
      .map((headerColumn: HeaderColumn) => headerColumn.columnKey);

    if (keys.length == 0) {
      return [] as ColumnKey[];
    }

    return keys as ColumnKey[];
  }

  /** カラムフィルター */
  private columnFilter(key: string, val: unknown) {
    return IsFilterMatch(
      this.selectFilter.filterClauses,
      key,
      val as string | number
    );
  }

  /** 請求年月フォーマット変換 */
  private convertInvoiceYearmonth(invoiceYearmonth: string): string {
    return appDate.formatDate(invoiceYearmonth, "YYYY年MM月")
  }

  /** PDFダウンロード */
  @Emit("pdf")
  private pdf(invoiceItem: InvoiceItem) {
    return invoiceItem;
  }
}
