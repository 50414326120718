

























































































































import { Component, Mixins } from "vue-property-decorator";
import Title from "@/components/common/Title.vue";
import AxiosMixin from "@/mixins/axiosMixin";
import UtilMixin from "@/mixins/utilMixin";
import { Agreement } from "@/views/invoicedashboard/types";

@Component({ components: { Title } })
export default class AgreementList extends Mixins(AxiosMixin, UtilMixin) {

  /** 変数 */

  /** 契約情報リスト */
  private agreements: Agreement[] = [];

  /** 関数 */
  
  created(): void {

    if (!this.MenuInfo.is_menu_invoice_dashboard) {
      this.$router.push("/");
    }

    this.fetchAgreementList();
  }

  /** 契約情報取得 */
  private fetchAgreementList() {
    this.postJsonCheck(window.base_url + "/api/invoicedashboard/agreements/get", {}, res => {
      this.agreements = res.data.agreements;
    });
  }
}
