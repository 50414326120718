


























import { Component, Prop, Vue } from "vue-property-decorator";
import { InvoiceNotice } from "#/model/bulletinboard";

@Component({})
export default class InvoiceNoticeViewer extends Vue {

  /** Prop */

  /** 請求お知らせ */
  @Prop({ default: () => [] }) invoiceNotices!: InvoiceNotice[];
}
