import { Vue, Component } from "vue-property-decorator";

/**
 * フォームバリデーションMixin
 */
@Component
export default class OtherPageMixin extends Vue {
  /** 別ウィンドウにURLを設定する */
  protected setWindowUrl(otherWindow: Window | null, url: string) {
    if (!url || !otherWindow) {
      return;
    }
    otherWindow.location.href = url;
    const pdfurlElement = otherWindow.document.getElementById("url");
    if (pdfurlElement) {
      pdfurlElement.innerHTML = url;
      pdfurlElement.setAttribute("href", url);
    }
  }

  /** 別ウィンドウにメッセージを設定する */
  protected setWindowMessage(otherWindow: Window | null, message: string) {
    if (!otherWindow) {
      return;
    }
    const element = otherWindow.document.getElementById("message");
    if (element) {
      element.innerHTML = message;
    }
  }

  /** 別ウィンドウに閉じるボタンを表示する */
  protected setWindowCloseButton(otherWindow: Window | null) {
    if (!otherWindow) {
      return;
    }
    const element = otherWindow.document.getElementById("close-btn");
    if (element) {
      element.style.display = "block";
    }
  }
}
