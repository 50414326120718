








































import { Component, Mixins, Ref, Watch } from "vue-property-decorator";
import AxiosMixin from "@/mixins/axiosMixin";
import { Ewelluser, DefaultEwelluser } from "@/types/ewelluser";
import MyPageItem from "@/components/userinfo/edit/MyPageItem.vue";
import { VForm } from "#/components/FileUpload.vue";
import * as master from "@/store/modules/master/types";
import { namespace } from "vuex-class";
import { BreadItem } from "@/types";
import EditMixin from "@/mixins/editMixin";

const Master = namespace(master.name);

@Component({ components: { MyPageItem } })
export default class extends Mixins(AxiosMixin, EditMixin) {
  @Ref("form") private readonly form!: VForm;

  @Master.Mutation(master.SET_MASTER) setMaster!: (payload: unknown) => {};

  private user: Ewelluser = DefaultEwelluser();

  private breadItems: BreadItem[] = [
    {
      text: "アカウント情報の確認",
      disabled: false,
      to: "/userinfo",
      exact: true
    },
    {
      text: "アカウント情報の編集",
      disabled: true,
      to: ""
    }
  ];

  /** 編集モードへの切り替え */
  @Watch("user", { deep: true }) protected watchUser() {
    if (this.IsLoaded) {
      this.setEditMode();
    }
  }

  async mounted() {
    this.updateData();
  }

  //データ更新
  private updateData() {
    this.postJsonCheck(window.base_url + "/api/accountinfo/get", {}, res => {
      this.user = res.data.ewell_user;
      this.setLoaded();
    });
  }

  //データ保存
  private async saveData() {
    if (!this.form.validate()) {
      await this.$openAlert("入力内容に不備があります");
      return;
    }

    this.user.ewell_user_certifications = this.user.ewell_user_certifications.filter(
      x => x.is_check === 1
    );

    this.user.ewell_user_practitioners = this.user.ewell_user_practitioners.filter(
      x => x.is_check === 1
    );

    this.postJsonCheck(
      window.base_url + "/api/accountinfo/save",
      {
        ewell_user: this.user
      },
      () => {
        //ログイン情報再取得
        this.postJsonCheck(window.base_url + "/api/master/get", {}, res => {
          if (res.data) {
            this.setMaster({
              loginUser: res.data.login_user,
              menuInfo: res.data.menu_info
            });
          }
          this.setNoEditMode();
          this.goBack();
        });
      }
    );
  }

  //戻る処理
  private goBack() {
    if (window.history.length > 1) {
      this.$router.go(-1);
    } else {
      this.$router.replace("/userinfo");
    }
  }

  //削除確認画面
  private deleteConfirm() {
    this.$router.push({ path: "/accountinfo/confirm/delete" });
  }
}
